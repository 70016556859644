<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>

          <validation-observer
              ref="refFormObserver"
          >

            <b-form>
              <b-row>

                <!-- Field:  name -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      label="کد کوپن"
                      label-for="code"
                  >
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                      <b-form-input
                          id="code"
                          v-model="defaultData.code"
                          :state="errors.length > 0 ? false:null"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Field: isOnline -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      label="نوع کوپن"
                      label-for="type"
                  >
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                    <v-select
                        v-model="defaultData.type"
                        :options="typeOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="type"
                        :state="errors.length > 0 ? false:null"
                    />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Field:  sessionsCount -->
                <b-col
                    v-if="defaultData.type !== 0"
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      label="ارزش"
                      label-for="couponValue"
                  >
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                    <b-form-input
                        id="couponValue"
                        v-model="defaultData.couponValue"
                        :state="errors.length > 0 ? false:null"
                    />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Field:  sessionsCount -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      label="شناسه محصول"
                      label-for="productId"
                  >
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                    <b-form-input
                        id="productId"
                        v-model="defaultData.productId"
                        :state="errors.length > 0 ? false:null"
                    />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Field:  startTime -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      label="تاریخ و زمان شروع"
                      label-for=""
                  >
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                    <input
                        type="text"
                        :id="`start${index}`"
                        placeholder="شروع"
                        v-model.trim="defaultData.startDate"
                        :state="errors.length > 0 ? false:null"
                        class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mt-1 mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
                    />
                    </validation-provider>
                    <custom-date-picker
                        v-model="defaultData.startDate"
                        auto-submit
                        format="YYYY-MM-DD HH:mm"
                        display-format="jYYYY/jMM/jDD HH:mm"
                        class=""
                        :element="`start${index}`"
                        type="datetime"
                        color="#5c6bc0"
                        :min="today"
                    ></custom-date-picker>
                  </b-form-group>
                </b-col>

                <!-- Field:  endTime -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      label="تاریخ و زمان پایان"
                      label-for=""
                  >
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                    <input
                        type="text"
                        :id="`end${index}`"
                        placeholder="پایان"
                        v-model.trim="defaultData.endDate"
                        :state="errors.length > 0 ? false:null"
                        class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mt-1 mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
                    />
                    </validation-provider>
                    <custom-date-picker
                        v-model="defaultData.endDate"
                        auto-submit
                        format="YYYY-MM-DD HH:mm"
                        display-format="jYYYY/jMM/jDD HH:mm"
                        class=""
                        :element="`end${index}`"
                        type="datetime"
                        color="#5c6bc0"
                        :min="today"
                    ></custom-date-picker>
                  </b-form-group>
                </b-col>

                <!-- Field: execution -->
                <b-col
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      label="نوع اجرا"
                      label-for="execution"
                  >
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                    <v-select
                        v-model="defaultData.execution"
                        :options="executionOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="execution"
                        :state="errors.length > 0 ? false:null"
                    />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Field:  sessionsCount -->
                <b-col
                    v-if="defaultData.execution === 1"
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      label="تعداد کل"
                      label-for="baseQuantity"
                  >
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                    <b-form-input
                        id="baseQuantity"
                        :state="errors.length > 0 ? false:null"
                        v-model="defaultData.baseQuantity"
                    />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Field:  sessionsCount -->
                <b-col
                    v-if="defaultData.execution === 1"
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      label="تعداد باقی مانده"
                      label-for="maintainedQuantity"
                  >
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                    <b-form-input
                        id="maintainedQuantity"
                        :state="errors.length > 0 ? false:null"
                        v-model="defaultData.maintainedQuantity"
                    />
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Field:  sessionsCount -->
                <b-col
                    v-if="defaultData.execution === 2"
                    cols="12"
                    md="6"
                >
                  <b-form-group
                      label="شناسه کاربر"
                      label-for="userId"
                  >
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                    <b-form-input
                        id="userId"
                        :state="errors.length > 0 ? false:null"
                        v-model="defaultData.userId"
                    />
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>
            </b-form>
          </validation-observer>

          <!-- Action Buttons -->
          <b-button
              variant="primary"
              class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="validationForm"
          >
            ثبت
          </b-button>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert, BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem, BForm, BFormGroup, BFormInput, BFormTextarea,
  BImg,
  BLink, BMedia,
  BRow, BTab, BTabs
} from "bootstrap-vue";
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from '@validations'

import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {CouponCreateRequest} from "@/libs/Api/Coupon";

export default {
  name: "CouponAdd",
  title: "ساخت کوپن - پنل ادمین مکس ",
  computed: {
    currentDate() {
      let today = new Date();
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date + ' ' + time;
    },
    today() {
      // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      // let test = new Date().toLocaleDateString('fa-IR',options);
      // return  test.replace(/([۰-۹])/g, token => String.fromCharCode(token.charCodeAt(0) - 1728));
      return new Date()
    }
  },
  data() {
    return {
      required,
      defaultData: {
        isDeleted: false,
        couponId: 0,
        type: 1,
        couponValue: '',
        startDate: "",
        endDate: "",
        productId: '',
        execution: 1,
        baseQuantity: '',
        maintainedQuantity: '',
        userId: '',
        hasUsedByUser: false,
        code: "",
        createDate: this.currentDate,
      },
      typeOptions: [
        {label: 'درصد', value: 1},
        {label: 'مبلغ', value: 2}
      ],
      executionOptions: [
        {label: 'عمومی', value: 1},
        {label: 'شخصی', value: 2}
      ],
    }
  },
  methods: {
    validationForm() {
      let _this = this;

      _this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          _this.createCoupon();
        }
      })
    },
    async createCoupon() {
      let _this = this;

      let couponCreateRequest = new CouponCreateRequest(_this);
      couponCreateRequest.setParams(_this.defaultData);
      await couponCreateRequest.fetch(function (content) {
        console.log(content)
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.$router.push({name: 'apps-coupons-list'})
      }, function (error) {
        console.log(error)
      })
    },
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
}
</script>

<style scoped>

</style>